import qs from 'qs'
import axios from 'axios'
import { getOptions, globalURL } from './singutils'
import { Modal } from 'ant-design-vue'

const baseURL = globalURL
const http = axios.create({
  baseURL,
  timeout: 5000
})

http.interceptors.request.use(
  config => {
    // Toast.loading({
    //   message: '加载中...',
    //   forbidClick: true
    // })

    config.headers = getOptions(config)
    const method = config.method
    if (method === 'post' || method === 'POST') {
      config.data = qs.stringify(config.data)
    }
    // config.data = {
    //   ...config.data,
    //   source: 2,
    //   channel: 4,
    //   userChannel: 1
    // }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

http.interceptors.response.use(
  response => {
    if (response.status !== 200) {
      Modal.error({
        title: '系统错误',
        content: response.data.msg
      })
    } else {
      if (+response.data.code === 0) {
        const data = response.data.data
        // 以下为了兼容分页数据返回
        if (response.data.total !== undefined) {
          return { data, total: response.data.total }
        }
        return data
      } else {
        Modal.error({
          title: '系统错误',
          content: response.data.msg
        })
        return Promise.reject(response)
      }
    }
  },
  error => {
    Modal.error({
      title: '系统错误',
      content: error.message
    })
    return Promise.reject(error)
  }
)

export default http
