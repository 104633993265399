
const saveData = (label, data) => {
  localStorage.setItem(label, JSON.stringify(data))
}
const getData = (label) => localStorage.getItem(label)

const removeData = (lable) => {
  Array.isArray(lable)
    ? lable.forEach(targetLabel =>
      localStorage.removeItem(targetLabel)
    )
    : localStorage.removeItem(lable)
}

export {
  saveData, getData, removeData
}
