import Vue from 'vue'
import VueRouter from 'vue-router'
import { Icon } from 'ant-design-vue'

Vue.use(VueRouter)
Vue.use(Icon)

const routes = [
  {
    path: '/',
    meta: { title: '天下行租车_全国连锁租车公司|天天超低价租车|服务至上租车公司' },
    name: 'Home',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'Index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "index" */ '../views/index.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "login" */ '../views/register.vue')
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "login" */ '../views/resetPassword.vue')
  },
  {
    path: '/newcar',
    name: 'NewCar',
    component: () => import(/* webpackChunkName: "newcar" */ '../views/newcar.vue')
  },
  {
    path: '/oldcar',
    name: 'OldCar',
    component: () => import(/* webpackChunkName: "oldcar" */ '../views/oldcar.vue')
  },
  {
    path: '/activity',
    name: 'Activity',
    component: () => import(/* webpackChunkName: "activity" */ '../views/activity.vue')
  },
  {
    path: '/newcar/carDetail',
    name: 'NewCarDetail',
    component: () => import(/* webpackChunkName: "carDetail" */ '../views/carDetail.vue')
  },
  {
    path: '/oldcar/carDetail',
    name: 'OldCarDetail',
    component: () => import(/* webpackChunkName: "carDetail" */ '../views/carDetail.vue')
  },
  {
    path: '/article',
    name: 'Article',
    component: () => import(/* webpackChunkName: "article" */ '../views/article.vue')
  }
]
const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
