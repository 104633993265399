<template>
  <div class="footer">
    <div class="footer-container">
      <div class="footer-top flex flex-jcsb flex-aic">
        <ul class="flex flex-aic">
          <li v-for="item in topDataList" :key="item.tit">
            <h3>{{ item.tit }}</h3>
            <span @click="item.content == '天下行租车'?   $utils.goTxxzcSite() : ''">{{ item.content }}</span>
          </li>
        </ul>
        <img @click="linkTo" src="@/assets/images/logo-2.png" alt="">
      </div>
      <div class="copy-right flex flex-column flex-jcc flex-aic">
        <span>本站一切内容版权归天下行租车有限公司所有</span>
        <span>闽ICP备10202277号Copyright @ 2019 txxzc.com All Rights Reserved.</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CWKFooter',

  data () {
    return {
      topDataList: [
        { tit: '友情链接', content: '天下行租车' },
        { tit: '联系我们', content: '400-155-6666' },
        { tit: '地址', content: '厦门市集美区珩田路388号' }
      ]
    }
  },

  mounted () {

  },

  methods: {
    linkTo () {
      if (this.$route.name === 'Index') {
        this.$router.go(0)
      } else {
        this.$router.push('/')
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .footer {
    height: 325px;
    background: #222222;
    color: #fff;
    &-container {
      width: @pageWidth;
      margin: 0 auto;
      background: #222222;
    }
    &-top {
      height: 205px;
      font-size: 14px;
      border-bottom: 1px solid #fff;
      img {
        cursor: pointer;
      }
      li {
        margin-right: 112px;
        &:nth-child(1) span {
          cursor: pointer;
        }
      }
      h3 {
        margin-bottom: 20px;
        font-size: 16px;
      }
    }
    .copy-right {
      height: 120px;
      font-size: 12px;
      span:nth-child(1) {
        margin-bottom: 15px;
      }
    }
  }
</style>
