import { findCityByName, storeList } from '@/api'
const state = () => ({
  cwkPhone: '400-155-6666', // 热线电话
  txxzcSite: 'http://www.txxzc.com', // 天下行租车官网
  currentCityGD: null,
  currentCity: null,
  currentPos: null,
  nearStore: null
})

const mutations = {
  setCurrentCityGD: (state, city) => {
    state.currentCityGD = city
  },
  setCurrentCity: (state, city) => {
    state.currentCity = city
  },
  setCurrentPos: (state, pos) => {
    state.currentPos = pos
  },
  setNearStore: (state, store) => {
    state.nearStore = store
  }
}

/* eslint-disable */
const actions = {
  // 获取当前城市
  getCurrentCity (context) {
    return new Promise((resolve, reject) => {
      AMapLoader.load({
        key: '1f454594cacec9bf800889a50dbed897', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.CitySearch'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        Loca: { // 是否加载 Loca， 缺省不加载
          version: '2.0' // Loca 版本
        }
      }).then((AMap) => {
        // 获取当前城市
        AMap.plugin('AMap.CitySearch', () => {
          const citySearch = new AMap.CitySearch()
          citySearch.getLocalCity(async (status, result) => {
            if (status === 'complete' && result.info === 'OK') {
              // 查询成功，result即为当前所在城市信息
              console.log('当前所在城市信息:', result)
              context.commit('setCurrentCityGD', result)
              const data = await findCityByName({ cityNm: context.state.currentCityGD.city.replace('市', '') })
              context.commit('setCurrentCity', data)
              resolve()
            } else {
              reject()
            }
          })
        })
      }).catch((e) => {
        reject()
        console.error(e) // 加载错误提示
      })
    })
  },

  // 获取精准定位
  getPosition (context) {
    return new Promise((resolve, reject) => {
      AMapLoader.load({
        plugins: ['AMap.Geolocation']
      }).then((AMap) => {
        // 获取精准地理位置，chrome浏览器会失败, edge可以
        AMap.plugin('AMap.Geolocation', () => {
          const geolocation = new AMap.Geolocation({
            // 是否使用高精度定位，默认：true
            enableHighAccuracy: true,
            // 设置定位超时时间，默认：无穷大
            timeout: 1000,
          })
          geolocation.getCurrentPosition((status, result) => {
            if (status === 'complete' && result.info === 'SUCCESS') {
              console.log('当前位置信息:', result)
              context.commit('setCurrentPos', result)
              resolve()
            } else {
              console.log('获取当前位置信息失败:', result)
              resolve()
            }
          })
        })
      }).catch((e) => {
        reject();
        console.error(e) // 加载错误提示
      })
    })
  },

  // 获取附近门店
  async getStoreList (context) {
    await context.dispatch('getCurrentCity')
    await context.dispatch('getPosition')
    console.log(context.state)
    const obj = {
      cityId: context.state.currentCity.regionaldataoid,
      cityName: context.state.currentCity.name,
      isFetchNearbyList: false,
      rzFlag: 1,
      isReturnStore: false
    }
    if (context.state.currentPos) {
      obj.isFetchNearbyList = true
      obj.lonAndLat = `${context.state.currentPos.position.lng}|${context.state.currentPos.position.lat}`
    }
    const data = await storeList(obj)
    context.commit('setNearStore', data[0].storeList[0])
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
