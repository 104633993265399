import request from '@/utils/request'

// 首页banner
const getBanner = (data) => request({
  url: '/config/sliderListCommon',
  method: 'get',
  data
})
// 点击咨询
const createRentBuyIntention = (data) =>
  request({
    url: '/buy/createRentBuyIntention',
    data,
    method: 'post'
  })

// 新车推荐
const rentBuyNewCar = (data) =>
  request({
    url: '/app/rentBuy/home/newCar',
    method: 'get',
    data
  })

// 二手车推荐
const rentBuySecondCar = (data) =>
  request({
    url: '/app/rentBuy/home/secondCar',
    method: 'get',
    data
  })

// 成交图片
const rentBuyCarOrderPic = (data) =>
  request({
    url: '/app/rentBuy/home/rentBuyCarOrderPic',
    method: 'get',
    data
  })

// 公司动态
const getCompanyNews = (data) =>
  request({
    url: '/config/getCompanyNewsNew',
    method: 'get',
    data
  })

// 热门城市
const hotList = (data) =>
  request({
    url: '/city/hotList',
    method: 'get',
    data
  })

// 热门品牌
const hotBrand = (data) =>
  request({
    url: '/car/hotBrand',
    method: 'get',
    data
  })

// 通过城市名获取城市信息
const findCityByName = (data) =>
  request({
    url: '/city/findCityByName',
    method: 'get',
    data
  })

// 城市列表
const cityList = (data) =>
  request({
    url: '/city/list',
    method: 'get',
    data
  })

// 品牌列表
const getBrandList = (data) =>
  request({
    url: '/car/getBrandList',
    method: 'get',
    data
  })

// 筛选条件列表
const listRentBuyCarFilterConditionByType = (data) =>
  request({
    url: '/app/rentBuy/listRentBuyCarFilterConditionByType',
    method: 'get',
    data
  })

// 获取车列表
const getRentBuyCarList = (data) =>
  request({
    url: '/car/getRentBuyCarList',
    method: 'post',
    data
  })

// 获取车详情
const rentBuyCarDetail = (data) =>
  request({
    url: '/car/rentBuyCarDetail',
    method: 'get',
    data
  })

// 获取门店列表
const storeList = (data) =>
  request({
    url: '/store/list',
    method: 'get',
    data
  })

// 获取金融方案
const financialScheme = (data) =>
  request({
    url: '/financialScheme/list',
    method: 'post',
    data
  })

export {
  getBanner,
  createRentBuyIntention,
  rentBuyNewCar,
  rentBuySecondCar,
  rentBuyCarOrderPic,
  hotList,
  findCityByName,
  cityList,
  getBrandList,
  listRentBuyCarFilterConditionByType,
  getRentBuyCarList,
  rentBuyCarDetail,
  storeList,
  financialScheme,
  getCompanyNews,
  hotBrand
}
