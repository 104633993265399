import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.css'
import utils from './utils'
import moment from 'moment'
import { Carousel, Icon, Pagination, Modal, message, Empty, ConfigProvider } from 'ant-design-vue'

Vue.config.productionTip = false
Vue.use(Carousel)
Vue.use(Icon)
Vue.use(Pagination)
Vue.use(Empty)
Vue.use(ConfigProvider)
Vue.use(Modal)
Vue.prototype.$message = message
Vue.prototype.$modal = Modal
Vue.prototype.$utils = utils
Vue.prototype.$moment = moment

store.dispatch('global/getStoreList').then(() => {})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
