<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <!-- <Header></Header> -->
      <Nav></Nav>
      <router-view></router-view>
      <Footer></Footer>
    </div>
  </a-config-provider>
</template>

<script>
// import Header from '@/components/Header.vue'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
export default {
  data () {
    return {
      locale: zhCN
    }
  },
  components: { Nav, Footer }
}
</script>

<style lang="less">
@import url('assets/styles/var.less');
@import url('assets/styles/base.css');
#app {
  padding-top: 100px;
}
.sub-title {
  position: relative;
  margin-top: 56px;
  color: #333;
  padding-left: 28px;
  font-size: 26px;
  font-weight: bold;
  line-height: 28px;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 28px;
    background: @primaryColor;
  }
  span {
    color: #a1a1a1;
    font-size: 14px;
    cursor: pointer;
  }
}
// antd 样式修改
.ant-pagination-item-active {
  border-color: @primaryColor !important;
}
.ant-pagination-item-active a {
  color: @primaryColor !important;
}
.ant-pagination-item:focus, .ant-pagination-item:hover {
  border-color: @primaryColor !important;
}
.ant-pagination-item:focus a, .ant-pagination-item:hover a {
  color: @primaryColor !important;
}
.ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
  border-color: @primaryColor !important;
  color: @primaryColor !important;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: @primaryColor !important;
}
.cwk-modal .ant-modal-title {
    line-height: 48px;
    text-align: center;
    font-size: 20px;
}
</style>
