<template>
  <div class="nav">
    <div class="nav-container flex flex-aic flex-jcsb">
      <div class="flex flex-aic h100">
        <img @click="linkTo" class="logo" src="@/assets/images/logo-1.png" alt="">
        <ul class="nav-list flex flex-aic">
          <router-link tag="li"  class="flex flex-aic" to="/index">首页</router-link>
          <router-link tag="li"  class="flex flex-aic" to="/newcar">新车</router-link>
          <router-link tag="li"  class="flex flex-aic" to="/oldcar">二手车</router-link>
          <li class="flex flex-aic" @click="navSwitch(3)">租车</li>
          <li tag="li"  class="flex flex-aic" @click="navSwitch(4)">公司动态</li>
          <li class="flex flex-aic" @click="navSwitch(5)">关于我们</li>
          <!-- <router-link tag="li" class="flex flex-aic" v-for="(item, index) in navList" :key="item.text" :class="{active: index === activeIndex}" @click="navSwitch(index)">{{ item.text }}</router-link> -->
        </ul>
      </div>
      <div class="phone flex">
        <img src="@/assets/images/phone.png" alt="">
        <div class="flex flex-column">
          <span>全国服务热线:</span>
          <span class="num">{{ phoneNum }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CWK2Nav',

  data () {
    return {
      phoneNum: '400-155-6666',
      activeIndex: 0,
      navList: [
        { text: '首页', path: '/index' },
        { text: '新车', path: '/newcar' },
        { text: '二手车', path: '/oldcar' },
        { text: '租车', path: 'txxzc' },
        { text: '公司动态', path: 'txxzc', other: '/#/aboutus/comAct' },
        { text: '关于我们', path: 'txxzc', other: '/#/aboutus/comintro' }
      ]
    }
  },

  mounted () {
  },

  methods: {
    navSwitch (index) {
      this.activeIndex = index
      const path = this.navList[index].path
      if (path === 'txxzc') {
        if (this.navList[index].other) {
          this.$utils.goTxxzcSite(this.navList[index].other)
        } else {
          this.$utils.goTxxzcSite()
        }
      } else {
        this.$router.push(path)
      }
    },
    linkTo () {
      if (this.$route.name === 'Index') {
        this.$router.go(0)
      } else {
        this.$router.push('/')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.nav {
  z-index: 1;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100px;
  background: #fff;
  box-shadow: 0px 3px 3px 0px #ddd;
  &-container {
    width: 1150px;
    height: 100%;
    margin: 0 auto;
  }
  &-list {
    height: 100%;
    color: #333;
    font-size: 18px;
    li {
      position: relative;
      margin-right: 60px;
      height: 100%;
      cursor: pointer;
      &:after {
        visibility: hidden;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: @primaryColor;
      }
      &.router-link-active {
        &:after {
          visibility: inherit;
        }
      }
    }
  }
}
.logo {
  margin-right: 100px;
  cursor: pointer;
}
.phone {
  font-size: 16px;
  color: #747474;
  img {
    margin-right: 10px;
    width: 47px;
    height: 47px;
  }
  .num {
    color: #f4981e;
    font-size: 22px;
    font-weight: bold;
  }
}
</style>
