import store from '../store'
const goTxxzcSite = (other) => {
  window.open(store.getters.txxzcSite + (other || ''))
}
/**
   * @desc 验证手机号
   * @returns {Boolean} true/false true为正确的手机号 0-35-9=>0到3+5到9
   */
const isPhone = (str) => {
  return /^[0-9]+$/.test(str) && str.length === 11
  // return /^((\+?[0-9]{1,4})|(\(\+86\)))?(13[0-9]|14[5-9]|15[0-35-9]|16[5-7]|17[0-8]|18[0-9]|19[158-9])\d{8}$/.test(
  //   str
  // )
}

// 将一个数组切割成指定长度的若干数组
const arrGroup = (array, subGroupLength) => {
  let index = 0
  const newArray = []
  while (index < array.length) {
    newArray.push(array.slice(index, index += subGroupLength))
  }
  return newArray
}

// 将一个数组切割成指定长度的若干数组
const arrGroupByLarray = (array, Larray) => {
  const newArray = []
  let index = 0
  for (let i = 0; i < Larray.length; i++) {
    newArray.push(array.slice(index, Larray[i] + index))
    index = Larray[i] + index
  }
  return newArray
}

// 对象数组去重
const uniqueFunc = (arr, uniId) => {
  const res = new Map()
  return arr.filter((item) => !res.has(item[uniId]) && res.set(item[uniId], 1))
}

export default {
  goTxxzcSite,
  isPhone,
  arrGroup,
  arrGroupByLarray,
  uniqueFunc
}
